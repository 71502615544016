const initialState = [];

function npTwitterReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_NP_TWITTER':
      state = action.payload;
      return state;
    case 'ERROR':
      return action.payload;
    default:
      return state;
  }
}

export default npTwitterReducer;