import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

function Programs() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="relative flex flex-row justify-center w-full min-h-[840px] bg-gradient-to-b from-[rgba(249,249,247,1)] to-[rgba(249,249,247,0)]">
        <div
          className={twJoin(
            "relative flex flex-col items-center h-full text-center px-[15px]",
            "w-screen pt-[144px] pb-[80px]",
            "lg:w-[1440px] lg:py-[80px]"
          )}
        >
          <p className={twJoin(
            "leading-[1.6] font-extrabold",
            "text-[28px]",
            "lg:text-[40px]")}>
            {t("programs.title")}
          </p>
          <p
            className={twJoin(
              "mt-[6px]",
              "text-[16px] leading-[1.69] mt-[16px]",
              "lg:text-[14px] lg:leading-[1.71] lg:mt-[6px]"
            )}
          >
            {t("programs.description1")}
            <br className="hidden lg:block" />
            {t("programs.description2")}
            <br className="hidden lg:block" />
            {t("programs.description3")}
          </p>

          <div
            className={twJoin("flex flex-col items-center", "w-screen mt-[48px]", "lg:w-[996px] lg:mt-[64px]")}
          >
            <div
              className={twJoin(
                "flex justify-between items-center w-full",
                "flex-col",
                "lg:flex-row"
              )}
            >
              <div
                className={twJoin(
                  "flex flex-row items-center justify-center",
                  "flex-col w-full",
                  "lg:flex-row lg:w-[480px]"
                )}
              >
                <img
                  src="images/img-program-education.webp"
                  width={183}
                  height={154}
                  alt="education"
                />
                <div
                  className={twJoin(
                    "text-center ml-[unset] mt-[12px]",
                    "lg:text-left lg:ml-[28px] lg:mt-[unset]"
                  )}
                >
                  <p className="text-[22px] leading-[2.18] font-extrabold ">
                    {t("programs.education")}
                  </p>
                  <p className={twJoin(
                    "leading-[1.71] ",
                    "mt-[4px] w-[320px]",
                    "lg:mt-[12px] lg:w-[255px]"
                  )}>
                    <Trans i18nKey="programs.educationDescription">
                      부트캠프, NCD <span className="text-[12px]">NEAR Certified Developer</span>,
                      캠퍼스 워크샵 등을 통해 NEAR 생태계에 온보딩할 수 있는 교육 프로그램 진행
                    </Trans>
                  </p>
                </div>
              </div>

              <div
                className={twJoin(
                  "bg-[#323232]",
                  "w-[320px] h-[1px] mx-[unset] my-[24px]",
                  "lg:w-[1px] lg:h-[154px] lg:mx-[31px] lg:my-[unset]"
                )}
              />

              <div
                className={twJoin(
                  "flex flex-row items-center justify-center",
                  "flex-col w-full",
                  "lg:flex-row lg:w-[480px]"
                )}
              >
                <img
                  src="images/img-program-assistance.webp"
                  width={183}
                  height={154}
                  alt="education"
                />
                <div
                  className={twJoin(
                    "text-center ml-[unset] mt-[12px]",
                    "lg:text-left lg:ml-[28px] lg:mt-[unset]"
                  )}
                >
                  <p className="text-[22px] leading-[2.18] font-extrabold ">
                    {t("programs.support")}
                  </p>
                  <p className={twJoin(
                    "leading-[1.71] ",
                    "mt-[4px] w-[320px]",
                    "lg:mt-[12px] lg:w-[255px]"
                  )}>
                    {t("programs.supportDescription")}
                  </p>
                </div>
              </div>
            </div>

            <div className={twJoin("flex flex-row justify-between", "my-[24px]", "lg:my-[18px]")}>
              <div className={twJoin("w-[480px] h-[1px] bg-[#323232]", "hidden", "lg:block")} />
              <div
                className={twJoin(
                  "h-[1px]",
                  "w-[320px] bg-[#323232]",
                  "lg:w-[36px] lg:bg-transparent"
                )}
              />
              <div className={twJoin("w-[480px] h-[1px] bg-[#323232]", "hidden", "lg:block")} />
            </div>

            <div
              className={twJoin(
                "flex justify-between items-center w-full",
                "flex-col",
                "lg:flex-row"
              )}
            >
              <div
                className={twJoin(
                  "flex flex-row items-center justify-center",
                  "flex-col w-full",
                  "lg:flex-row lg:w-[480px]"
                )}
              >
                <img
                  src="images/img-program-funding.webp"
                  width={183}
                  height={154}
                  alt="education"
                />
                <div
                  className={twJoin(
                    "text-center ml-[unset] mt-[12px]",
                    "lg:text-left lg:ml-[28px] lg:mt-[unset]"
                  )}
                >
                  <p className="text-[22px] leading-[2.18] font-extrabold ">
                    {t("programs.funding")}
                  </p>
                  <p className={twJoin(
                    "leading-[1.71] ",
                    "mt-[4px] w-[320px]",
                    "lg:mt-[12px] lg:w-[255px]"
                  )}>
                    {t("programs.fundingDescription")}
                  </p>
                  <Link to="/grants">
                    <p className="cursor-pointer text-[13px] text-[#4ec284] underline leading-[1.85] font-bold">
                      {t("programs.fundingApply")}
                    </p>
                  </Link>
                </div>
              </div>

              <div
                className={twJoin(
                  "bg-[#323232]",
                  "w-[320px] h-[1px] mx-[unset] my-[24px]",
                  "lg:w-[1px] lg:h-[154px] lg:mx-[31px] lg:my-[unset]"
                )}
              />

              <div
                className={twJoin(
                  "flex flex-row items-center justify-center",
                  "flex-col w-full",
                  "lg:flex-row lg:w-[480px]"
                )}
              >
                <img
                  src="images/img-program-marketing.webp"
                  width={183}
                  height={154}
                  alt="education"
                />
                <div
                  className={twJoin(
                    "text-center ml-[unset] mt-[12px]",
                    "lg:text-left lg:ml-[28px] lg:mt-[unset]"
                  )}
                >
                  <p className="text-[22px] leading-[2.18] font-extrabold ">
                    {t("programs.marketing")}
                  </p>
                  <p className={twJoin(
                    "leading-[1.71] ",
                    "mt-[4px] w-[320px]",
                    "lg:mt-[12px] lg:w-[255px]"
                  )}>
                    {t("programs.marketingDescription")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Programs;
