import React from "react";
import { twJoin } from "tailwind-merge";
import { NEARKOREA_DISCORD_URL, NEARKOREA_MAIL_URL, NEARKOREA_MEDIUM_URL, NEARKOREA_X_URL } from "../../utils/apis";

function Footer() {
  return (
    <div
      className={twJoin(
        "flex flex-row justify-center w-full h-[88px] bg-[#0f1919] mt-auto",
        "px-[24px]",
        "lg:px-[unset]"
      )}
    >
      <div className="flex flex-row justify-between items-center w-[1112px] h-full">
        <img
          className={twJoin("hidden", "lg:block")}
          src="images/logo-nearkorea-gray.webp"
          width={128}
          height={35}
          alt="nearkorea-logo"
        />
        <div className="flex flex-row justify-center">
          <p className="text-[12px] text-[#898c8b]">© 2022 NEAR Korea Hub.</p>
        </div>
        <div className="flex flex-row justify-between items-center gap-[16px]">
          <img
            className="cursor-pointer"
            src="images/icon-x.webp"
            width={40}
            height={40}
            alt="twitter"
            onClick={() => {
              window.open(NEARKOREA_X_URL);
            }}
          />
          <img
            className="cursor-pointer"
            src="images/icon-discord.webp"
            width={40}
            height={40}
            alt="twitter"
            onClick={() => {
              window.open(NEARKOREA_DISCORD_URL);
            }}
          />
          <img
            className="cursor-pointer"
            src="images/icon-medium.webp"
            width={40}
            height={40}
            alt="medium"
            onClick={() => {
              window.open(NEARKOREA_MEDIUM_URL);
            }}
          />
          <img
            className="cursor-pointer"
            src="images/icon-email.webp"
            width={40}
            height={40}
            alt="email"
            onClick={(e) => {
              window.location = NEARKOREA_MAIL_URL;
              e.preventDefault();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
