import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import Home from "./components/pages/Home";
import Grants from "./components/pages/Grants";
import Programs from "./components/pages/Programs";
import News from "./components/pages/News";
import Contact from "./components/pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  let fontFamily;
  let fontSize;
  switch (i18n.resolvedLanguage) {
    case "kr":
      fontFamily = "font-nanumSqaureNeo";
      fontSize = "text-[14px]";
      break;
    case "en":
      fontFamily = "font-manrope";
      fontSize = "text-[14px]";
      break;
    default:
      break;
  }

  return (
    <BrowserRouter>
      <div className={`flex flex-col min-h-screen ${fontFamily} ${fontSize}`}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/grants" element={<Grants />} />
          <Route path="/programs" element={<Programs />} />
          {/* <Route path="/news" element={<News />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
        <ScrollToTop />
      </div>
    </BrowserRouter>
  );
}

export default App;