import React, { useEffect, useState } from "react";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../redux/actions/newsActions";
import { fetchNKTwitter } from "../../redux/actions/nkTwitterActions";
import { fetchNPTwitter } from "../../redux/actions/npTwitterActions";
import { NEARKOREA_X_FOLLOW_URL, NEARPROTOCOL_X_URL } from "../../utils/apis";


// const Card = ({ title, news_url, img_url, date, message }) => {

//   const { i18n } = useTranslation();

//   const dateObj = new Date(date);
//   let formattedDate = "";
//   if (i18n.resolvedLanguage === "kr") {
//     formattedDate = dateObj.toLocaleDateString('ko-KR', {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     }).replace(/\//g, '.');
//   } else if (i18n.resolvedLanguage === "en") {
//     formattedDate = dateObj.toLocaleDateString('en-CA', {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     }).replace(/\//g, '.');
//   }

//   const imgUrl = img_url === undefined || img_url === "" ? "images/img-noimage.png" : img_url;

//   return (
//     <div className="flex-none">
//       <div
//         className={twJoin(
//           "flex flex-col h-[371px] bg-[#fff] rounded-[10px] border-[1px] border-[#1d1d1b]",
//           "max-w-[330px]",
//           "lg:max-w-[358px]"
//         )}
//       >
//         <img
//           src={imgUrl}
//           className="w-full h-[187px] min-h-[187px] bg-slate-400 rounded-t-[10px]"
//           alt="post"
//         />

//         <div className="flex flex-col h-full justify-between p-[30px]">
//           <div className="flex flex-col text-left !font-nanumSqaureNeo">
//             <p className="text-[18px] font-extrabold leading-[1.5] text-ellipsis overflow-hidden line-clamp-3">{title}</p>
//             {/* <p className="h-[72px] text-[15px] font-light leading-[1.6] mt-[16px] text-ellipsis overflow-hidden line-clamp-3">
//               {description}
//             </p> */}
//           </div>
//           <div className="flex flex-row justify-between">
//             <p className="text-[12px] font-semibold leading-[2] text-[#808080]">{formattedDate}</p>
//             <p
//               className="cursor-pointer text-[13px] font-bold leading-[1.85] text-[#4ec284] underline"
//               onClick={() => {
//                 window.open("https://awesomenear.com" + news_url);
//               }}
//             >
//               {message}
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  // const [current, setCurrent] = useState(0);
  // const [style, setStyle] = useState({
  //   marginLeft: `-${current}00`,
  // });
  // const moveSlide = (direction) => {
  //   let nextIndex = current + direction; // left: -1, right: 1
  //   if (nextIndex < 0) {
  //     nextIndex = mainNews.length - 1;
  //   } else if (nextIndex >= mainNews.length) {
  //     nextIndex = 0;
  //   }

  //   setCurrent(nextIndex);
  // };
  // useEffect(() => {
  //   setStyle({ marginLeft: `-${current}00%` });
  // }, [current]);

  // Fetch data from aws s3 or global state to show latest three news of NEAR Protocol.
  
  // const newsData = useSelector((state) => state.news);

  // useEffect(() => {
  //   if (newsData.length > 0) return;

  //   dispatch(
  //     fetchNews()
  //   );
  // }, [dispatch, newsData.length]);

  // const [mainNews, setMainNews] = useState([]);
  // useEffect(() => {
  //   setMainNews(newsData.slice(0, 3));
  // }, [newsData]);

  // Fetch data from google spreadsheet to show latest three posts oof NEAR Korea Hub twitter.
  const npTwitterData = useSelector((state) => state.npTwitter);
  const nkTwitterData = useSelector((state) => state.nkTwitter);

  useEffect(() => {
    if (npTwitterData.length > 0 && nkTwitterData.length > 0) return;

    dispatch(fetchNPTwitter());
    dispatch(fetchNKTwitter());
  }, [dispatch, npTwitterData.length, nkTwitterData.length]);

  return (
    <div className="flex flex-col">
      {/* 첫 번째 영역 */}
      <div
        className={twJoin(
          "relative flex flex-row justify-center w-full bg-gradient-to-b from-[rgba(249,249,247,1)] to-[rgba(249,249,247,0)]",
          "h-fit pb-[20px]",
          "lg:h-[840px]"
        )}
      >
        <img
          src="images/img-home-decoration-left.webp"
          width={362}
          height={301}
          alt="decoration-left"
          className="hidden lg:block absolute top-[100px] left-0 object-cover"
        />
        <img
          src="images/img-home-decoration-right.webp"
          width={268}
          height={332}
          alt="decoration-right"
          className="hidden lg:block absolute top-[120px] right-0 object-cover"
        />

        <div
          className={twJoin(
            "relative flex flex-col items-center h-full text-center",
            "w-screen pt-[144px]",
            "lg:w-[1440px] lg:pt-[80px]"
          )}
        >
          <p
            className={twJoin(
              "font-extrabold px-[15px]",
              "text-[32px] leading-[1.44]",
              "lg:text-[50px] lg:leading-[1.28]"
            )}
          >
            {t("home.welcome1")}
            <br />
            {t("home.welcome2")}
          </p>
          <p
            className={twJoin(
              "mt-[20px] leading-[1.71] px-[15px]",
              "text-[16px]",
              "lg:text-[14px]"
            )}
          >
            {t("home.description1")}
            <br className="hidden lg:block" />
            {t("home.description2")}
            <br className="hidden lg:block" />
            {t("home.description3")}
          </p>
          <img
            className={twJoin(
              "bottom-0",
              "w-screen static mt-[20px]",
              "lg:w-[1028px] lg:h-[496px] lg:absolute lg:mt-[20px]"
            )}
            src="images/img-home-wallpaper.webp"
            width={1028}
            height={496}
            alt="wallpaper"
          />
        </div>
      </div>

      {/* 두 번째 영역 */}
      {/* <div
        className={twJoin(
          "relative flex flex-row justify-center w-full bg-[#f0faf5]",
          "h-fit min-h-fit pb-[48px]",
          "lg:h-[791px] lg:pb-[unset]"
        )}
      >
        <div
          className={twJoin(
            "relative flex flex-col items-center h-full text-center px-[15px]",
            "w-screen pt-[64px]",
            "lg:w-[1440px] lg:pt-[80px]"
          )}
        >
          <p
            className={twJoin(
              " font-extrabold",
              "text-[32px] leading-[1.44]",
              "lg:text-[40px] lg:leading-[1.6]"
            )}
          >
            {t("home.nearnow")}
          </p>
          <p
            className={twJoin(
              "leading-[1.71]",
              "w-[320px] text-[16px] mt-[16px]",
              "lg:w-[unset] lg:text-[14px] lg:mt-[6px]"
            )}
          >
            {t("home.nearnowDescription")}
          </p>

          {mainNews.length === 3 ? (
            <>
              <div
                className={twJoin("flex-row mt-[58px] text-left gap-[16px]", "hidden", "lg:flex")}
              >
                {mainNews.map((post, index) => {
                  const message = t("home.nearnowDetail");
                  return <Card {...post} key={index} message={message} />;
                })}
              </div>

              <div
                className={twJoin(
                  "h-[381px] mt-[32px] overflow-hidden",
                  "block max-w-[330px]",
                  "lg:hidden lg:max-w-[358px]"
                )}
              >
                <div className="flex" style={style}>
                  {mainNews.map((post, index) => {
                    const message = t("home.nearnowDetail");
                    return <Card {...post} key={index} message={message} />;
                  })}
                </div>
              </div>
              <div
                className={twJoin(
                  "flex-row justify-between items-center mt-[16px] w-full max-w-[360px] px-[15px]",
                  "flex",
                  "lg:hidden"
                )}
              >
                <img
                  className={twJoin(
                    "cursor-pointer w-[32px] h-[32px]",
                    current === 0 ? "cursor-not-allowed opacity-30" : "cursor-pointer opacity-100"
                  )}
                  src="images/icon-arrow-left-black.webp"
                  alt="left"
                  onClick={() => {
                    if (current === 0) return;
                    moveSlide(-1);
                  }}
                />
                <div className="flex flex-row gap-[12px]">
                  {mainNews.map((post, index) => {
                    return (
                      <div
                        key={index}
                        className={twJoin(
                          current === index ? "bg-[#4ec284]" : "bg-[#d8d8d8]",
                          "w-[8px] h-[8px] rounded-full"
                        )}
                      />
                    );
                  })}
                </div>
                <img
                  className={twJoin(
                    "cursor-pointer w-[32px] h-[32px]",
                    current === mainNews.length - 1
                      ? "cursor-not-allowed opacity-30"
                      : "cursor-pointer opacity-100"
                  )}
                  src="images/icon-arrow-right-black.webp"
                  alt="right"
                  onClick={() => {
                    if (current === mainNews.length - 1) return;
                    moveSlide(1);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="flex flex-row justify-center items-center w-full h-[471px] mt-[64px] lg:mt-[58px]">
              <img className="w-[36px] h-[36px]" src="images/gif-loader.gif" alt="loader"/>
            </div>
          )}

          <Link to="/news">
            <Button className="mt-[48px] lg:mt-[64px]" title={`${t("home.nearnowButton")}`} />
          </Link>
        </div>
      </div> */}
      <div
        className={twJoin(
          "relative flex flex-row justify-center w-full bg-[#f0faf5]",
          "min-h-fit pb-[48px]",
          "lg:pb-[108px]"
        )}
      >
        <div
          className={twJoin(
            "relative flex flex-col items-center h-full text-center px-[15px]",
            "w-screen pt-[64px]",
            "lg:w-[1440px] lg:pt-[80px]"
          )}
        >
          <p
            className={twJoin(
              " font-extrabold",
              "text-[32px] leading-[1.44]",
              "lg:text-[40px] lg:leading-[1.6]"
            )}
          >
            {t("home.nearnow")}
          </p>
          <p
            className={twJoin(
              "leading-[1.71]",
              "w-[320px] text-[16px] mt-[16px]",
              "lg:w-[unset] lg:text-[14px] lg:mt-[6px]"
            )}
          >
            {t("home.nearnowDescription")}
          </p>
          <Button
            className="mt-[32px]"
            title={`${t("home.nearnowButton")}`}
            onClick={() => {
              window.open(NEARPROTOCOL_X_URL);
            }}
          />

          <div
            className={twJoin(
              "flex gap-[16px] text-left text-[13px]",
              "mt-[32px] flex-col min-h-[530px] justify-center",
              "lg:mt-[48px] lg:flex-row lg:min-h-[190px]"
            )}
          >
            {npTwitterData.length === 3 ? (
              <>
                {npTwitterData.map((item, index) => {

                  const isRetweet = item.is_retweet === true;
                  const pt = isRetweet ? "pt-[12px]" : "pt-[20px]";

                  const isLongName = item.name.length > 28;
                  const name = isLongName ? item.name.slice(0, 28) + "..." : item.name;
                  return (
                    <div
                      key={index}
                      onClick={() => window.open(item.post_url)}
                      className={twJoin(
                        "cursor-pointer flex flex-col gap-[12px] w-full h-fit max-w-[360px] border-[1px] border-black rounded-[10px]",
                        "bg-white",
                        `${pt} pb-[20px] px-[16px]`
                      )}
                    >
                      {isRetweet && (
                        <div className="flex flex-row items-center gap-[6px]">
                          <img
                            className="w-[16px] h-[16px]"
                            src="images/icon-retweet.webp"
                            width={16}
                            height={16}
                            alt="retweet"
                          />
                          <p className="text-[10px] text-[#536471] font-extrabold ">
                            NEAR Protocol Retweeted
                          </p>
                        </div>
                      )}
                      <div className="flex flex-row w-full">
                        <div className="w-[48px] h-[48px] min-w-[48px] min-h-[48px] border-[1px] border-[#eaeaea] rounded-full">
                          <img
                            src={item.profile_image_url}
                            width={48}
                            height={48}
                            className="rounded-full"
                            alt="post"
                          />
                        </div>
                        <div className="flex flex-col ml-[12px]">
                          {isLongName ? (
                            <div className="flex flex-col items-start !font-manrope">
                              <p className="font-extrabold">{name}</p>
                              <p className="text-[11px] font-extrabold text-[#536471]">
                                @{item.username}
                              </p>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center !font-manrope">
                              <p className="font-extrabold">{name}</p>
                              <p className="text-[11px] ml-[5px] font-extrabold text-[#536471]">
                                @{item.username}
                              </p>
                            </div>
                          )}

                          <p
                            className="text-[13px] !font-manrope text-[#0f1419] font-normal w-[254px] leading-[1.54] mt-[4px] text-ellipsis overflow-hidden line-clamp-3"
                          >
                            {item.text}
                          </p>

                          <p className="text-[11px] font-bold text-[#536471] leading-[1.82] mt-[8px]">
                            {item.created_at}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex flex-row justify-center items-center w-full h-[152px]">
                <img className="w-[36px] h-[36px]" src="images/gif-loader.gif" alt="loader" />
              </div>
            )}
          </div>
        </div>
      </div>


      {/* 세 번째 영역 */}
      <div
        className={twJoin(
          "relative flex flex-row justify-center w-full",
          "min-h-fit pb-[48px]",
          "lg:pb-[108px]"
        )}
      >
        <div
          className={twJoin(
            "relative flex flex-col items-center h-full text-center px-[15px]",
            "w-screen pt-[64px]",
            "lg:w-[1440px] lg:pt-[80px]"
          )}
        >
          <p
            className={twJoin(
              " font-extrabold",
              "text-[32px] leading-[1.44]",
              "lg:text-[40px] lg:leading-[1.6]"
            )}
          >
            {t("home.neartwitter")}
          </p>
          <p
            className={twJoin(
              "leading-[1.71]",
              "w-[320px] text-[16px] mt-[16px]",
              "lg:w-[unset] lg:text-[14px] lg:mt-[6px]"
            )}
          >
            {t("home.neartwitterDescription")}
          </p>
          <Button
            className="mt-[32px]"
            title={`${t("home.neartwitterButton")}`}
            onClick={() => {
              window.open(NEARKOREA_X_FOLLOW_URL);
            }}
          />

          <div
            className={twJoin(
              "flex gap-[16px] text-left text-[13px]",
              "mt-[32px] flex-col min-h-[530px] justify-center",
              "lg:mt-[48px] lg:flex-row lg:min-h-[190px]"
            )}
          >
            {nkTwitterData.length === 3 ? (
              <>
                {nkTwitterData.map((item, index) => {

                  const isRetweet = item.is_retweet === true;
                  const pt = isRetweet ? "pt-[12px]" : "pt-[20px]";

                  const isLongName = item.name.length > 28;
                  const name = isLongName ? item.name.slice(0, 28) + "..." : item.name;
                  return (
                    <div
                      key={index}
                      onClick={() => window.open(item.post_url)}
                      className={twJoin(
                        "cursor-pointer flex flex-col gap-[12px] w-full h-fit max-w-[360px] border-[1px] border-black rounded-[10px]",
                        `${pt} pb-[20px] px-[16px]`
                      )}
                    >
                      {isRetweet && (
                        <div className="flex flex-row items-center gap-[6px]">
                          <img
                            className="w-[16px] h-[16px]"
                            src="images/icon-retweet.webp"
                            width={16}
                            height={16}
                            alt="retweet"
                          />
                          <p className="text-[10px] text-[#536471] font-extrabold ">
                            NEAR Korea Retweeted
                          </p>
                        </div>
                      )}
                      <div className="flex flex-row w-full">
                        <div className="w-[48px] h-[48px] min-w-[48px] min-h-[48px] border-[1px] border-[#eaeaea] rounded-full">
                          <img
                            src={item.profile_image_url}
                            width={48}
                            height={48}
                            className="rounded-full"
                            alt="post"
                          />
                        </div>
                        <div className="flex flex-col ml-[12px]">
                          {isLongName ? (
                            <div className="flex flex-col items-start !font-manrope">
                              <p className="font-extrabold">{name}</p>
                              <p className="text-[11px] font-extrabold text-[#536471]">
                                @{item.username}
                              </p>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center !font-manrope">
                              <p className="font-extrabold">{name}</p>
                              <p className="text-[11px] ml-[5px] font-extrabold text-[#536471]">
                                @{item.username}
                              </p>
                            </div>
                          )}

                          <p
                            className="text-[13px] !font-manrope text-[#0f1419] font-normal w-[254px] leading-[1.54] mt-[4px] text-ellipsis overflow-hidden line-clamp-3"
                          >
                            {item.text}
                          </p>

                          <p className="text-[11px] font-bold text-[#536471] leading-[1.82] mt-[8px]">
                            {item.created_at}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex flex-row justify-center items-center w-full h-[152px]">
                <img className="w-[36px] h-[36px]" src="images/gif-loader.gif" alt="loader" />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* 네 번재 영역 */}
      <div
        className={twJoin(
          "relative flex flex-col items-center w-full text-center px-[15px] text-[#fff] bg-cover bg-[url('/public/images/img-forest.webp')]",
          "pt-[64px] h-fit pb-[88px]",
          "lg:pt-[128px] lg:h-[566px] lg:pb-[unset]"
        )}
      >
        <p
          className={twJoin(
            " font-extrabold",
            "w-[320px] text-[32px] leading-[1.44]",
            "lg:w-[unset] lg:text-[40px] lg:leading-[1.6]"
          )}
        >
          {t("home.southpole")}
        </p>
        <p
          className={twJoin(
            "leading-[1.71]",
            "w-[320px] text-[16px] mt-[16px]",
            "lg:w-[unset] lg:text-[14px] lg:mt-[6px]"
          )}
        >
          {t("home.southpoleDescription1")}
          <br className="hidden lg:block" />
          {t("home.southpoleDescription2")}
          <br className="hidden lg:block" />
          {t("home.southpoleDescription3")}
        </p>
        <img
          className="mt-[28px]"
          src="images/logo-south-pole.webp"
          width={240}
          height={48}
          alt="south-pole"
        />
        <Button
          className="mt-[36px]"
          title={`${t("home.southpoleButton")}`}
          onClick={() => {
            window.open("https://near.org/blog/near-climate-neutral-product");
          }}
        />
      </div>
    </div>
  );
}

export default Home;
