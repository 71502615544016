import React from "react";

function Button({ className, title, icon, type, onClick }) {
  return (
    <button type={type} className={"flex flex-row justify-center items-center w-[237px] h-[52px] px-[20px] py-[15px] rounded-[6px] font-bold text-[18px] text-[#fff] leading-[1.33] bg-[#1d1d1b] hover:bg-[#3d3d39] active:bg-[#292926] active:text-opacity-50 " + className} onClick={onClick}>
      <p>{title}</p>
      {icon && <img src={`/images/${icon}.webp`} width={20} height={20} alt="icon" />}
    </button>
  );
}

export default Button;
