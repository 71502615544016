import React, { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

const menu = [
  {
    id: 0,
    name: "Home",
    url: "/",
  },
  {
    id: 1,
    name: "Grants",
    url: "/grants",
  },
  {
    id: 2,
    name: "Programs",
    url: "/programs",
  },
  // {
  //   id: 3,
  //   name: "News",
  //   url: "/news",
  // },
  {
    id: 4,
    name: "Contact",
    url: "/contact",
  },
];

const langs = {
  kr: { option: "한국어 KR", name: "KR" },
  en: { option: "영어 EN", name: "EN" },
};

function Header() {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [isOpened, setIsOpened] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolled]);

  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.resolvedLanguage);
  const [isLanguageOpened, setIsLanguageOpened] = React.useState(false);
  const languageRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isLanguageOpened && languageRef.current && !languageRef.current.contains(event.target)) {
        setIsLanguageOpened(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  });

  // 스크롤 방지
  useEffect(() => {
    document.body.style.overflow = isOpened ? "hidden" : "auto";
  }, [isOpened]);

  return (
    <>
      {/* 모바일 */}
      <div
        className={twJoin(
          "z-50 lg:hidden w-screen fixed top-0 flex flex-col justify-between items-center p-[20px] bg-white shadow-[0px_2px_12px_0px_rgba(0,0,0,0.08)]",
          isOpened
            ? "h-[400px] transition-all duration-300 ease-in"
            : "h-[80px] transition-all duration-300 ease-out"
        )}
      >
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row items-center gap-[16px]">
            {!isOpened ? (
              <img
                className="cursor-pointer w-[36px] h-[36px]"
                src="images/icon-m-hamburger.webp"
                alt="open"
                onClick={() => setIsOpened(!isOpened)}
              />
            ) : (
              <img
                className="cursor-pointer w-[36px] h-[36px]"
                src="images/icon-m-close.webp"
                alt="close"
                onClick={() => setIsOpened(!isOpened)}
              />
            )}
            <Link to="/">
              <img src="images/logo-nearkorea.webp" width={110} height={30} alt="nearkorea-logo" onClick={() => setIsOpened(false)} />
            </Link>
          </div>

          <div className="relative flex flex-row justify-center items-center">
            <div
              onClick={() => {
                const changeLanguage = selectedLanguage === "kr" ? "en" : "kr";
                i18n.changeLanguage(changeLanguage);
                setSelectedLanguage(changeLanguage);
              }}
              className="cursor-pointer flex flex-row justify-center items-center w-[44px] h-[44px]"
            >
              <img src="images/icon-language.webp" width={20} height={20} alt="language" />
            </div>
          </div>
        </div>

        <div
          className={twJoin(
            "flex flex-col justify-between w-full h-full px-[52px] pt-[40px] pb-[20px] delay-300",
            isOpened ? "visible" : "invisible"
          )}
        >
          {menu.map(({ id, name, url }) => {
            return (
              <NavLink
                key={id}
                to={url}
                className={({ isActive }) =>
                  isActive ? "text-[#4ec284] font-bold" : "font-medium"
                }
                onClick={() => setIsOpened(!isOpened)}
              >
                <p
                  className={twJoin(
                    "cursor-pointer flex-1 text-[20px]",
                    isOpened ? "opacity-100" : "opacity-0"
                  )}
                >
                  {name}
                </p>
              </NavLink>
            );
          })}
        </div>
      </div>

      {!isOpened ? null : (
        <div
          className="z-40 fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.6)] backdrop-blur-[3px]"
          onClick={() => setIsOpened(!isOpened)}
        />
      )}

      {/* 데스크탑 */}
      <div className="hidden lg:flex relative flex-row justify-center items-center w-full h-[99px]">
        <div
          className={`transition-all duration-300 z-50 flex flex-row justify-center w-full h-[70px] bg-white ${
            isScrolled
              ? "fixed top-[0px] shadow-[0px_2px_12px_0px_rgba(0,0,0,0.08)]"
              : "top-[-50px]"
          }`}
        >
          <div className="flex flex-row items-center w-[1440px] h-full px-[152px]">
            <Link to="/">
              {isScrolled ? (
                <img
                  className="mr-[98px]"
                  src="images/icon-near.webp"
                  width={30}
                  height={30}
                  alt="nearkorea-logo"
                />
              ) : (
                <img
                  src="images/logo-nearkorea.webp"
                  width={128}
                  height={35}
                  alt="nearkorea-logo"
                />
              )}
            </Link>
            <div className="flex flex-row justify-center w-full">
              {menu.map(({ id, name, url }) => {
                return (
                  <NavLink
                    key={id}
                    to={url}
                    className={({ isActive }) => (isActive ? "text-[#4ec284] font-bold" : "")}
                  >
                    <p
                      className={`cursor-pointer m-[18px] font-bold hover:text-[#666] active:text-[#2ee683] ${
                        isScrolled ? "text-[16px]" : "text-[17px]"
                      }`}
                    >
                      {name}
                    </p>
                  </NavLink>
                );
              })}
            </div>
            <div className="relative">
              <div
                ref={languageRef}
                onClick={() => {
                  setIsLanguageOpened(!isLanguageOpened);
                }}
                className="cursor-pointer flex flex-row justify-between items-center min-w-[105px] h-[44px] p-[10px] font-bold bg-[#f7f7f7] rounded-[6px] hover:bg-[#fafafa] active:bg-[#f0f0f0]"
              >
                <div className="flex flex-row items-center">
                  <img src="images/icon-language.webp" width={20} height={20} alt="language" />
                  <p className="ml-[8px]">{langs[selectedLanguage].name}</p>
                </div>
                {isLanguageOpened ? (
                  <img src="images/icon-arrow-up.webp" width={20} height={20} alt="arrow" />
                ) : (
                  <img src="images/icon-arrow-down.webp" width={20} height={20} alt="arrow" />
                )}
              </div>
              {isLanguageOpened && (
                <div className="absolute flex flex-col text-center w-[105px] p-[6px] rounded-[6px] top-[50px] bg-[#fff] shadow-[0px_1px_8px_0px_rgba(0,0,0,0.1)]">
                  {Object.keys(langs).map((lang) => (
                    <button
                      key={lang}
                      className="cursor-pointer w-full h-[36px] flex-1 rounded-[4px] text-[13px] leading-[36px] font-bold hover:bg-[#f7f7f7]"
                      type="submit"
                      onClick={() => {
                        i18n.changeLanguage(lang);
                        setSelectedLanguage(lang);
                      }}
                    >
                      {langs[lang].option}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
