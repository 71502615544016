import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import emailjs from "@emailjs/browser";
import { twJoin } from "tailwind-merge";
import { NEARKOREA_DISCORD_URL, NEARKOREA_X_URL } from "../../utils/apis";

function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const [isPopup, setIsPopup] = useState(false);

  const { t } = useTranslation();
  const form = useRef();

  const onSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        "template_contact",
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("subject").value = "";
          document.getElementById("message").value = "";

          setIsPopup(true);
          setTimeout(() => {
            setIsPopup(false);
          }, 3000);
          setIsLoading(false);
        },
        (error) => {
          console.log(error.text);
          setIsLoading(false);
        }
      );
  };

  return (
      <div className="flex flex-col">
        <div className="relative flex flex-row justify-center w-full h-fit min-h-[840px] bg-gradient-to-b from-[rgba(249,249,247,1)] to-[rgba(249,249,247,0)]">
          <div className={twJoin(
            "relative z-10 flex flex-col items-center h-full text-center px-[15px]",
            "w-screen pt-[144px] pb-[80px]",
            "lg:w-[1440px] lg:py-[80px] "
          )}>

            {/* 영역 1 - 페이지 제목 */}
            <p className={twJoin(
              "leading-[1.6] font-extrabold",
              "text-[28px]",
              "lg:text-[40px]"
            )}>{t("contact.title")}</p>

            {/* 영역 2 - 컨택 포인트 */}
            <div className={twJoin(
              "flex flex-row justify-center",
              "w-full mt-[24px] gap-[24px]",
              "lg:w-[1112px] lg:mt-[36px]"
            )}>

              <div
                className={twJoin(
                  "cursor-pointer flex flex-col lg:flex-row items-center justify-center bg-transparent",
                )} 
                onClick={() => {
                  window.open(NEARKOREA_X_URL);
                }}
              >
                <img
                  src="images/icon-x-black.webp"
                  width={48}
                  height={48}
                  alt="x"
                />
              </div>

              <div
                className={twJoin(
                  "cursor-pointer flex flex-col lg:flex-row items-center justify-center bg-transparent",
                )} 
                onClick={() => {
                  window.open(NEARKOREA_DISCORD_URL);
                }}
              >
                <img
                  src="images/icon-discord-black.webp"
                  width={48}
                  height={48}
                  alt="discord"
                />
              </div>
            </div>

            {/* 영역 3 - 제출 양식 */}
            <div className={twJoin(
              "relative flex flex-row justify-between h-fit min-h-[482px]  border-[1px] border-[#1d1d1b] bg-[#fff] rounded-[10px]",
              "w-full p-[30px] mt-[36px]",
              "lg:w-[1112px] lg:p-[48px] lg:mt-[48px]"
            )}>
              <form className="w-full lg:w-[unset]" ref={form} onSubmit={onSubmit}>
                <div className={twJoin(
                  "flex flex-col text-left",
                  "gap-[20px]",
                  "lg:gap-[16px]"
                )}>

                  <div className={twJoin(
                    "flex items-start",
                    "flex-col",
                    "lg:flex-row"
                  )}>
                    <label className="min-w-[61px] font-bold pt-[unset] lg:pt-[10px]">{t("contact.formName")}</label>
                    <input
                      required
                      className={twJoin(
                        "h-[44px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                        "w-full ml-0 mt-[6px]",
                        "lg:w-[460px] lg:ml-[21px] lg:mt-0"
                      )}
                      type="text"
                      name="name"
                      id="name"
                      placeholder={`${t("contact.formNamePlaceholder")}`}
                    />
                  </div>
                  
                  <div className={twJoin(
                    "flex items-start",
                    "flex-col ",
                    "lg:flex-row"
                  )}>
                    <label className="min-w-[61px] font-bold pt-[unset] lg:pt-[10px]">{t("contact.formEmail")}</label>
                    <input
                      required
                      className={twJoin(
                        "h-[44px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                        "w-full ml-0 mt-[6px]",
                        "lg:w-[460px] lg:ml-[21px] lg:mt-0"
                      )}
                      type="text"
                      name="email"
                      id="email"
                      placeholder={`${t("contact.formEmailPlaceholder")}`}
                    />
                  </div>
                  
                  <div className={twJoin(
                    "flex items-start",
                    "flex-col ",
                    "lg:flex-row"
                  )}>
                    <label className="min-w-[61px] font-bold pt-[unset] lg:pt-[10px]">{t("contact.formSubject")}</label>
                    <input
                      required
                      className={twJoin(
                        "h-[44px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                        "w-full ml-0 mt-[6px]",
                        "lg:w-[460px] lg:ml-[21px] lg:mt-0"
                      )}
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder={`${t("contact.formSubjectPlaceholder")}`}
                    />
                  </div>

                  <div className={twJoin(
                    "flex align-top items-start",
                    "flex-col",
                    "lg:flex-row"
                  )}>
                    <label className="min-w-[61px] font-bold pt-[unset] lg:pt-[10px]">
                      {t("contact.formMessage")}
                    </label>
                    <textarea
                      required
                      className={twJoin(
                        "h-[138px] min-h-[138px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                        "w-full ml-0 mt-[6px]",
                        "lg:w-[460px] lg:ml-[21px] lg:mt-0"
                      )}
                      type="text"
                      name="message"
                      id="message"
                      placeholder={`${t("contact.formMessagePlaceholder")}`}
                    ></textarea>
                  </div>

                  <div
                    className={`flex flex-row justify-end mt-[unset] lg:mt-[4px] ${
                      isLoading ? "cursor-not-allowed" : ""
                    }`}
                  >
                    <Button
                      title={`${t("contact.submit")}`}
                      icon="icon-arrow-right-white"
                      type="submit"
                      className={`text-[13px] w-full lg:w-[140px] min-w-[140px] h-[44px] justify-between ${
                        isLoading ? "pointer-events-none" : ""
                      }`}
                    />
                  </div>
                </div>
              </form>
              <div className="hidden w-[384px] h-[384px] lg:block">
                <img src="images/img-contact.webp" width={384} height={384} alt="contact" />
              </div>

              <div
                className={`${
                  isPopup ? "visible opacity-100" : "invisible opacity-0"
                } flex transition-all duration-300 flex-row justify-center items-center z-10 absolute inset-0 m-auto w-[360px] h-[128px] rounded-[10px] bg-[#fff] border-[1px] border-[#1d1d1b33]`}
              >
                <img src="images/icon-success.webp" width={32} height={32} alt="success" />
                <p className="text-[20px] font-bold leading-[1.6] text-[#323232] ml-[12px]">
                  {t("contact.sent")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Contact;
