import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [isShown, setIsShown] = React.useState(false);
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsShown(true);
      } else {
        setIsShown(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return <img className={`transition-all w-[36px] h-[36px] lg:w-[48px] lg:h-[48px] duration-300 fixed z-10 cursor-pointer bottom-[100px] lg:bottom-[24px] ${isShown ? "right-[12px] lg:right-[24px]" : "right-[-50px]"}`} src="images/icon-scroll-to-top.webp" onClick={scrollToTop} width={48} height={48} alt="scroll-to-top" />;
}

export default ScrollToTop;
