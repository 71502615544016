import React, { useRef, useState } from "react";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { twJoin } from "tailwind-merge";

function Grants() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const form = useRef();

  const scrollToSubmit = () => {
    window.scroll({
      top: 868,
      behavior: "smooth",
    });
  };

  // 첨부 파일 로직
  // const [files, setFiles] = useState([]);
  // const [fileName, setFileName] = useState("");
  
  // const handleDelete = (e) => {
  //   document.getElementById("file").value = "";
  //   setFiles([]);
  //   setFileName("");
  // };

  // useEffect(() => {
  //   const handleChange = (e) => {
  //     setFiles(Array.from(e.target.files || []));
  //     setFileName(e.target.files[0].name || "");
  //   };

  //   const fileInput = document.getElementById("file");
  //   fileInput.addEventListener("change", handleChange);

  //   return () => fileInput.removeEventListener("change", handleChange);
  // }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const onSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    console.log(form.current);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        "template_grants",
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          document.getElementById("project").value = "";
          document.getElementById("category").value = "";
          document.getElementById("contact").value = "";
          document.getElementById("description").value = "";
          document.getElementById("resource").value = "";
          // 첨부 파일 로직
          // setFiles([]);
          // setFileName(`${t("grants.applyPlaceholderFile")}`);
          setIsPopup(true);
          setTimeout(() => {
            setIsPopup(false);
          }, 3000);
          setIsLoading(false);
        },
        (error) => {
          console.log(error.text);
          setIsLoading(false);
        }
      );
  };

  return (
    <div className="flex flex-col">
      <div className="relative flex flex-row justify-center w-full min-h-[840px] bg-gradient-to-b from-[rgba(249,249,247,1)] to-[rgba(249,249,247,0)]">
        <div
          className={twJoin(
            "relative flex flex-col items-center h-full text-center px-[15px]",
            "w-screen pt-[144px] pb-[48px]",
            "lg:w-[1440px] lg:py-[80px]"
          )}
        >
          {/* 1. 페이지 제목 */}
          <p className={twJoin("font-extrabold leading-[1.6]", "text-[28px]", "lg:text-[40px]")}>
            {t("grants.title1")}
            <br
              className={twJoin(i18n.resolvedLanguage === "kr" ? "hidden" : "block", "lg:hidden")}
            />
            {t("grants.title2")}
          </p>

          {/* 2. 페이지 설명 */}
          <p
            className={twJoin(
              "mt-[6px]",
              "w-full text-[16px] leading-[1.69] mt-[16px]",
              "lg:w-[629px] lg:text-[14px] lg:leading-[1.71] lg:mt-[6px]"
            )}
          >
            {t("grants.description1")}
            <br
              className={twJoin(
                i18n.resolvedLanguage === "en" ? "hidden lg:block" : "hidden lg:block"
              )}
            />
            {t("grants.description2")}
          </p>

          {/* 3. 프로세스 설명 */}
          <div
            className={twJoin(
              "flex flex-col mt-[34px] px-[20px]",
              "w-full",
              "lg:px-[unset] lg:w-[629px]"
            )}
          >
            {/* 프로세스 1 */}
            <div
              className={twJoin(
                "flex flex-row justify-between items-center h-[108px]",
                "w-full",
                "lg:w-[629px]"
              )}
            >
              <div className="flex flex-row items-center">
                <p
                  className={twJoin(
                    "w-[42px] font-extrabold",
                    "text-[28px] leading-[1.71]",
                    "lg:text-[34px] lg:leading-[1.41]"
                  )}
                >
                  01
                </p>
                <p
                  className={twJoin(
                    "font-semibold text-left",
                    "text-[20px] ml-[24px] leading-[1.2]",
                    "lg:text-[22px] lg:ml-[48px] lg:leading-[2.18]"
                  )}
                >
                  {t("grants.process1")}
                </p>
              </div>
              <Button
                className={twJoin(
                  "h-[44px] font-semibold text-[13px] justify-between",
                  "w-[95px] ml-[25px] px-[12px]",
                  "lg:w-[140px] lg:ml-[unset] lg:px-[20px]"
                )}
                title={`${t("grants.apply")}`}
                icon="icon-arrow-down-white"
                onClick={scrollToSubmit}
              />
            </div>
            <div className={twJoin("h-[1px] bg-[#323232]", "w-full", "lg:w-[629px]")} />

            {/* 프로세스 2 */}
            <div
              className={twJoin(
                "flex flex-row items-center",
                "w-full h-[96px]",
                "lg:w-[629px] lg:h-[108px]"
              )}
            >
              <p
                className={twJoin(
                  "w-[42px] font-extrabold",
                  "text-[28px] leading-[1.71]",
                  "lg:text-[34px] lg:leading-[1.41]"
                )}
              >
                02
              </p>
              <p
                className={twJoin(
                  "font-semibold text-left",
                  "text-[20px] ml-[24px] leading-[1.2]",
                  "lg:text-[22px] lg:ml-[48px] lg:leading-[2.18]"
                )}
              >
                {t("grants.process2")}
              </p>
            </div>
            <div className={twJoin("h-[1px] bg-[#323232]", "w-full", "lg:w-[629px]")} />

            {/* 프로세스 3 */}
            <div
              className={twJoin(
                "flex flex-row items-center",
                "w-full h-[96px]",
                "lg:w-[629px] lg:h-[108px]"
              )}
            >
              <p
                className={twJoin(
                  "w-[42px] font-extrabold",
                  "text-[28px] leading-[1.71]",
                  "lg:text-[34px] lg:leading-[1.41]"
                )}
              >
                03
              </p>
              <p
                className={twJoin(
                  "font-semibold text-left",
                  "text-[20px] ml-[24px] leading-[1.2]",
                  "lg:text-[22px] lg:ml-[48px] lg:leading-[2.18]"
                )}
              >
                {t("grants.process3")}
              </p>
            </div>
            <div className={twJoin("h-[1px] bg-[#323232]", "w-full", "lg:w-[629px]")} />

            {/* 프로세스 4 */}
            <div
              className={twJoin(
                "flex flex-row items-center",
                "w-full h-[96px]",
                "lg:w-[629px] lg:h-[108px]"
              )}
            >
              <p
                className={twJoin(
                  "w-[42px] font-extrabold",
                  "text-[28px] leading-[1.71]",
                  "lg:text-[34px] lg:leading-[1.41]"
                )}
              >
                04
              </p>
              <p
                className={twJoin(
                  "font-semibold text-left",
                  "text-[20px] ml-[24px] leading-[1.2]",
                  "lg:text-[22px] lg:ml-[48px] lg:leading-[2.18]"
                )}
              >
                {t("grants.process4")}
              </p>
            </div>
            <div className={twJoin("h-[1px] bg-[#323232]", "w-full", "lg:w-[629px]")} />

            {/* 프로세스 5 */}
            <div
              className={twJoin(
                "flex flex-row items-center",
                "w-full h-[96px]",
                "lg:w-[629px] lg:h-[108px]"
              )}
            >
              <p
                className={twJoin(
                  "w-[42px] font-extrabold",
                  "text-[28px] leading-[1.71]",
                  "lg:text-[34px] lg:leading-[1.41]"
                )}
              >
                05
              </p>
              <p
                className={twJoin(
                  "font-semibold text-left",
                  "text-[20px] ml-[24px] leading-[1.2]",
                  "lg:text-[22px] lg:ml-[48px] lg:leading-[2.18]"
                )}
              >
                {t("grants.process5")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-row w-full justify-center h-fit bg-[#f0faf5]">
        <div
          className={twJoin(
            "relative flex flex-col h-full px-[15px] mr-0",
            "w-screen pt-[48px] pb-[64px]",
            "lg:w-[1440px] lg:py-[80px]"
          )}
        >
          <form className="flex flex-row justify-center w-full" ref={form} onSubmit={onSubmit}>
            <div
              className={twJoin(
                "flex flex-col bg-[#fff] rounded-[10px] border-[1px] border-black text-[14px]",
                "w-full p-[30px] gap-[20px]",
                "lg:w-[800px] lg:p-[48px] lg:gap-[16px]"
              )}
            >
              <div className={twJoin("flex align-top items-start", "flex-col", "lg:flex-row")}>
                <label className="min-w-[90px] max-w-[90px] font-semibold pt-[unset] lg:pt-[10px]">
                  {t("grants.applyProject")}
                </label>
                <input
                  required
                  className={twJoin(
                    "w-full h-[44px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                    "ml-[unset] mt-[6px]",
                    "lg:ml-[36px] lg:mt-[unset]"
                  )}
                  type="text"
                  name="project"
                  id="project"
                  placeholder={`${t("grants.applyPlaceholderProject")}`}
                />
              </div>
              <div className={twJoin("flex align-top items-start", "flex-col", "lg:flex-row")}>
                <label className="min-w-[90px] max-w-[90px] font-semibold pt-[unset] lg:pt-[10px]">
                  {t("grants.applyCategory")}
                </label>
                <input
                  required
                  className={twJoin(
                    "w-full h-[44px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                    "ml-[unset] mt-[6px]",
                    "lg:ml-[36px] lg:mt-[unset]"
                  )}
                  type="text"
                  name="category"
                  id="category"
                  placeholder={`${t("grants.applyPlaceholderCategory")}`}
                />
              </div>
              <div className={twJoin("flex align-top items-start", "flex-col", "lg:flex-row")}>
                <label className="min-w-[90px] max-w-[90px] font-semibold pt-[unset] lg:pt-[10px]">
                  {t("grants.applyContact")}
                </label>
                <input
                  required
                  className={twJoin(
                    "w-full h-[44px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                    "ml-[unset] mt-[6px]",
                    "lg:ml-[36px] lg:mt-[unset]"
                  )}
                  type="text"
                  name="contact"
                  id="contact"
                  placeholder={`${t("grants.applyPlaceholderContact")}`}
                />
              </div>
              <div className={twJoin("flex align-top items-start", "flex-col", "lg:flex-row")}>
                <label className="min-w-[90px] max-w-[90px] font-semibold pt-[unset] lg:pt-[10px]">
                  {t("grants.applyDescription")}
                </label>
                <textarea
                  required
                  className={twJoin(
                    "w-full h-[138px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                    "ml-[unset] mt-[6px]",
                    "lg:ml-[36px] lg:mt-[unset]"
                  )}
                  type="text"
                  name="description"
                  id="description"
                  placeholder={`${t("grants.applyPlaceholderDescription")}`}
                ></textarea>
              </div>
              
              <div className={twJoin("flex align-top items-start", "flex-col", "lg:flex-row")}>
                <label className="min-w-[90px] max-w-[unset] dt:max-w-[90px] font-semibold">
                  {t("grants.applyFile")}
                </label>
                <input
                  required
                  className={twJoin(
                    "w-full h-[44px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                    "ml-[unset] mt-[6px]",
                    "lg:ml-[36px] lg:mt-[unset]"
                  )}
                  type="text"
                  name="resource"
                  id="resource"
                  placeholder={`${t("grants.applyPlaceholderFile")}`}
                />
              </div>

              {/* <div className={twJoin("flex align-top items-start", "flex-col", "lg:flex-row")}>
                <label className="min-w-[90px] max-w-[90px] font-semibold pt-[unset] lg:pt-[10px]">
                  {t("grants.applyFile")}
                </label>
                <div className="flex flex-row w-full items-center justify-between mt-[6px] lg:mt-[unset]">
                  <p
                    className={twJoin(
                      "w-full h-[44px] px-[16px] py-[10px] rounded-[6px] bg-[#f7f7f7] focus:outline-none",
                      "ml-[unset]",
                      "lg:ml-[36px]"
                    )}
                  >
                    <span className={`text-[#323232] ${files.length > 0 ? "" : "text-opacity-30"}`}>
                      {files.length > 0 ? fileName : t("grants.applyPlaceholderFile")}
                    </span>
                  </p>

                  {files.length > 0 ? (
                    <button
                      className={twJoin(
                        "cursor-pointer flex flex-row justify-center items-center py-[15px] rounded-[6px] font-bold text-[13px] text-[#fff] leading-[1.33] bg-[#1d1d1b] h-[44px] ml-[16px]",
                        "w-[84px] min-w-[84px] px-[12px]",
                        "lg:w-[100px] lg:min-w-[100px] lg:px-[20px] lg:hover:bg-[#3d3d39] lg:active:bg-[#292926] lg:active:text-opacity-50"
                      )}
                      onClick={handleDelete}
                    >
                      {t("grants.applyDeleteFile")}
                    </button>
                  ) : (
                    <label
                      htmlFor="file"
                      className={twJoin(
                        "cursor-pointer flex flex-row justify-center items-center py-[15px] rounded-[6px] font-bold text-[13px] text-[#fff] leading-[1.33] bg-[#1d1d1b] h-[44px] ml-[16px]",
                        "w-[84px] min-w-[84px] px-[12px]",
                        "lg:w-[100px] lg:min-w-[100px] lg:px-[20px] lg:hover:bg-[#3d3d39] lg:active:bg-[#292926] lg:active:text-opacity-50"
                      )}
                    >
                      {t("grants.applyUpload")}
                    </label>
                  )}
                </div>

                <input className="absolute w-0 h-0 hidden" type="file" name="file" id="file" />
              </div> */}

              <div
                className={twJoin(
                  "flex flex-row justify-end mt-[32px]",
                  isLoading ? "cursor-not-allowed" : ""
                )}
              >
                <Button
                  title={`${t("grants.applySubmit")}`}
                  icon="icon-arrow-right-white"
                  type="submit"
                  className={twJoin(
                    `text-[13px] h-[44px] justify-between ${
                      isLoading ? "pointer-events-none" : ""
                    }`,
                    "w-full",
                    "lg:w-[140px] lg:min-w-[140px]"
                  )}
                />
              </div>
            </div>
          </form>
          <div
            className={`${
              isPopup ? "visible opacity-100" : "invisible opacity-0"
            } flex transition-all duration-300 flex-row justify-center items-center z-10 absolute inset-0 m-auto w-[360px] h-[128px] rounded-[10px] bg-[#fff] border-[1px] border-[#1d1d1b33]`}
          >
            <img src="images/icon-success.webp" width={32} height={32} alt="success" />
            <p className="text-[20px] font-bold leading-[1.6] text-[#323232] ml-[12px]">
              {t("grants.sent")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Grants;
